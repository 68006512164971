<template>

    <div>

        <XModal ref="XModal" :show-overlay="true">

            <template #header>
                Форма запроса отчета от СППР
            </template>
            <template #default>

                <div :style="{width: '50vw'}">

                    <h5>Выберите отчет</h5>
                    <XDropDown
                        v-model="selected_report" :filter="true"
                        :options="reports"
                        class="w-full mb-3"
                        emptyFilterMessage="Нет результатов"
                        emptyMessage="Нет результатов"
                        option-label="reportName"
                        @change="reportTypeSelected"/>

                    <div v-if="!this.iframe && this.selected_report">

                        <Message :closable="false" :severity="'warn'">Для данного отчета не получилось сгенерировать
                            форму ввода параметров
                        </Message>


                    </div>

                    <div v-if="iframe">

                        <iframe ref="iframe" :srcdoc="iframe"
                                style="display: block; width: 100%; border: 0; min-height: 300px;"
                                @load="iframeLoaded"></iframe>

                        <div class="mt-3">
                            {{ resultXml }}
                        </div>


                    </div>


                </div>

            </template>

            <template #footerLeftOfHide>
                <Button :disabled="(!this.iframe || !this.selected_report)"
                        class="p-button p-button-success p-button-outlined mt-3" label="Отправить запрос отчета в СППР"
                        @click="sendReportRequest"/>
            </template>


        </XModal>

    </div>

</template>

<script>
import {XDropDown, XModal} from "@xnpmpackages/xcomponents";

export default {
    name: "RequestReportCard",
    emits: ['okBtnClicked'],
    data() {
        return {
            iframe: null,
            resultXml: null,

            reports: null,
            selected_report: null,
        }
    },
    methods: {

        async sendReportRequest() {

            if (!this.$refs.iframe.contentWindow.check_all_validity()) {

                this.$xapi.xShowModalError({text: 'Неверно заполнены параметры запроса отчета.'});

            } else {

                let htmlToXml = this.$refs.iframe.contentWindow.htmlToXML(this.$refs.iframe.contentWindow.document.body);
                //htmlToXml = htmlToXml.replace(/<\?xml.*\?>(.*)/gi, '$1');

                try {

                    this.$xapi.showBlockModal('Отправка запроса в СППР...');

                    await this.$xapi.post(
                        '/sppr-requests',
                        {
                            action: 'sendReportRequest',
                            reportUid: this.selected_report.reportUid,
                            params: htmlToXml,
                        },
                    );

                    this.$xapi.xnotify('Запрос на формирование отчета отправлен.')
                    this.$emit('okBtnClicked');
                    this.$refs.XModal.hide();

                } finally {
                    this.$xapi.hideBlockModal();
                }

            }


        },
        reportTypeSelected($event) {

            this.iframe = $event.value.htmlForm;

        },
        async getReportsList() {

            let response = await this.$xapi.post(
                '/sppr-requests',
                {
                    action: 'getAllowedReportsTypes',
                },
            );

            this.reports = response.data.reports;

        },
        show() {

            //this.selected_report = null;
            //this.iframe = null;
            this.getReportsList();
            this.$refs.XModal.show();

        },
        applyCssToIframe: function () {

            let $iframe = this.$refs.iframe;

            document.head.querySelectorAll('style').forEach(item => $iframe.contentWindow.document.head.appendChild(item.cloneNode(true)));
            document.head.querySelectorAll('link[rel="stylesheet"]').forEach(item => $iframe.contentWindow.document.head.appendChild(item.cloneNode(true)));


            let cssLink = document.createElement("link");
            cssLink.href = "digitDocsIframe.css";
            cssLink.rel = "stylesheet";
            cssLink.type = "text/css";

            $iframe.contentWindow.document.head.appendChild(cssLink);

            const theme = localStorage.getItem('theme');
            if (theme === 'light') {

                cssLink = document.createElement("link");
                cssLink.href = "digitDocsIframeLightOverride.css";
                cssLink.rel = "stylesheet";
                cssLink.type = "text/css";

                $iframe.contentWindow.document.head.appendChild(cssLink);

            }
        },
        iframeLoaded() {

            this.$refs.iframe.contentDocument.querySelectorAll('input[type=text]')?.forEach((item) => {
                item.classList.add('p-inputtext');
            });

            this.$refs.iframe.contentDocument.querySelectorAll('input[type=number]')?.forEach((item) => {
                item.classList.add('p-inputtext');
            });

            this.$refs.iframe.contentDocument.querySelectorAll('input[type=datetime-local]')?.forEach((item) => {
                item.classList.add('p-inputtext');
            });

            this.updateButtonsClasses();

            this.applyCssToIframe();

        },
        updateButtonsClasses() {

            const $iframe = this.$refs.iframe;

            $iframe.contentDocument.querySelectorAll('button.add')?.forEach((item) => {
                item.classList.add('p-button');
                item.classList.add('p-button-success');
                item.classList.add('p-button-sm');
                item.classList.add('p-button-outlined');
                item.classList.add('p-component');
            });

            $iframe.contentDocument.querySelectorAll('button.remove')?.forEach((item) => {
                item.classList.add('p-button');
                item.classList.add('p-button-warning');
                item.classList.add('p-button-sm');
                item.classList.add('p-button-outlined');
                item.classList.add('p-component');
            });

            $iframe.contentDocument.querySelectorAll('select')?.forEach((item) => {
                item.classList.add('p-cascadeselect');
                item.classList.add('p-component');
                item.classList.add('p-inputwrapper');
                item.classList.add('p-inputtext');
                item.classList.add('xselect');
            });

            $iframe.contentDocument.querySelectorAll('textarea')?.forEach((item) => {
                item.classList.add('p-inputtextarea');
                item.classList.add('p-inputtext');
                item.classList.add('p-component');
                item.classList.add('p-inputtextarea-resizable');
            });


        },

    },
    components: {XDropDown, XModal},

}
</script>

<style scoped>

</style>