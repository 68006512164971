<template>

    <div>

        <XModal ref="XModal" :show-close-button="true" :show-overlay="true">
            <template #header>
                Просмотр карточки документа
            </template>
            <template #default>

                <div :style="{minWidth: '30vw', maxWidth: '80vw'}">

                    <table class="p-datatable-table" style="width: 100%;">
                        <tbody>

                        <tr>
                            <td>Дата формирования отчета</td>
                            <td>
                                {{ unixTimeStampToDateTime(cardItem.reportDate) }}
                            </td>
                        </tr>
                        <tr>
                            <td>Наименование отчета</td>
                            <td>
                                {{ cardItem.reportName }}
                            </td>
                        </tr>
                        <tr>
                            <td>Идентификатор запроса</td>
                            <td>
                                {{ cardItem.request_guid }}
                            </td>
                        </tr>
                        <tr>
                            <td>Идентификатор ответа</td>
                            <td>
                                {{ cardItem.response_guid }}
                            </td>
                        </tr>


                        </tbody>
                    </table>

                </div>


            </template>
            <template #footerLeftOfHide>
                <Button v-if="showOpenButton" class="p-button-help p-button-outlined" icon="fas fa-eye"
                        label="Посмотреть отчет" @click="openReport"/>
                <Button class="p-button-success p-button-outlined" icon="fas fa-download" label="Выгрузить отчет"
                        @click="downloadReport"/>
            </template>

        </XModal>


    </div>

</template>

<script>
import {DataFilters, Helper, XModal} from "@xnpmpackages/xcomponents";

export default {
    name: "SpprReportCard",
    mixins: [DataFilters, Helper],
    components: {XModal},
    data() {
        return {
            cardItem: null,
        }
    },
    methods: {
        async openReport() {

            try {

                this.$xapi.showBlockModal('Загрузка...');

                let response = await this.$xapi.getFile('/sppr-reports/getReportFile/' + this.cardItem?.id);
                let url = window.URL.createObjectURL(response.data)

                window.open(url, '_blank');

            } catch (e) {

                this.$xapi.xerror('Не удалось открыть файл');

            } finally {
                this.$xapi.hideBlockModal();
            }


        },
        async downloadReport() {

            try {

                this.$xapi.showBlockModal('Выгрузка файла...');
                await this.downloadFileFromUrl('/sppr-reports/getReportFile/' + this.cardItem.id);

            } catch (e) {

                this.$xapi.xerror('Не удалось выгрузить файл');

            } finally {
                this.$xapi.hideBlockModal();
            }

        },
        show($cardItem) {

            this.cardItem = $cardItem;
            this.$refs.XModal.show();

        }
    },
    computed: {

        showOpenButton() {
            return this.cardItem.reportFile.split('.').pop() === 'html';
        }

    }
}
</script>

<style scoped>


.p-datatable-table {
    border-collapse: collapse;
}

.p-datatable-table tbody > tr > td, .p-datatable-table thead > tr > td {
    padding: 5px 20px;
}

.p-datatable-table > tbody > tr:nth-child(even) {
    /*background-color: #0a3622;*/
    background-color: var(--surface-ground);
}


</style>