<template>

    <div>

        <TablePage ref="TablePage" mainUrl="/sppr-reports" @tableItemsClick="tableItemsClick">

            <template #toolBarRightSide>
                <Button class="p-button p-button-outlined mr-3" label="Запросить отчет"
                        @click="requestReportShowCard"/>
            </template>

            <template #tableColumns="SlotProps">

                <Column :sortable="true" field="reportDate">
                    <template #header>
                        <SearchDate v-model="SlotProps.filters['reportDate']" label="Дата"/>
                    </template>
                    <template #body="slotProps">
                        {{ unixTimeStampToDateTime(slotProps.data?.['reportDate']) }}
                    </template>
                </Column>

                <Column :sortable="true" field="reportName" filter-field="reportName">
                    <template #header>
                        <SearchInput v-model="SlotProps.filters['reportName']" label="Название отчета"/>
                    </template>
                </Column>

            </template>

        </TablePage>

        <SpprReportCard ref="SpprReportCard"/>
        <RequestReportCard ref="RequestReportCard"/>

    </div>

</template>

<script>
import {DataFilters, SearchDate, SearchInput, TablePage} from "@xnpmpackages/xcomponents";
import SpprReportCard from "./SpprReports/SpprReportCard";
import RequestReportCard from "@/components/app/sppr_requests/RequestReportCard";

export default {
    name: "SpprReports",
    mixins: [DataFilters],
    components: {RequestReportCard, SpprReportCard, SearchInput, TablePage, SearchDate},
    methods: {

        tableItemsClick($event) {
            this.$refs.SpprReportCard.show($event.data);
        },

        async requestReportShowCard() {

            this.$refs.RequestReportCard.show();

        },

    },
    data() {
        return {}
    },
    mounted() {

        //this.$refs.TablePage.getTableRows(true);

    }
}
</script>

<style scoped>

</style>